
<template>
    <div>
        <CSDialog
            :dialogTitle="submitParams.formId ? '修改考核表' : '添加考核表'"
            dialogWidth="670px" :is-submitting="isSubmitting"
            :dialogVisible="addAssessmentVisivle"
            @onClose="addAssessmentVisivle = false"
            @onConfirm="submitCommonAssessment"
        >
            <div
                slot="dialog-content" class="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="margin-bottom: 15px;">
                    <label>表名</label>
                    <input
                        type="text"
                        style="width: 400px; height: 40px; border-radius: 4px; border: 1px solid #979797; padding-left: 10px;"
                        v-model="submitParams.name"
                        placeholder="请输入"
                    />
                </div>
                <template
                    v-if="
                        submitParams.target != 3 &&
                        [1001, 1002].includes(
                            Number(staffInfo.dutyType)
                        )
                    "
                >
                    <div style="margin-bottom: 15px;">
                        <label>部门</label>
                        <CSSelect
                            iWidth="36px"
                            height="40px"
                        >
                            <select
                                style=""
                                v-model="checkedOrg"
                            >
                                <option value="">
                                    请选择
                                </option>
                                <option
                                    v-for="department in orgList"
                                    :key="department.code"
                                    :value="department"
                                >
                                    {{ department.name }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </template>
                <div style="margin-bottom: 15px;">
                    <label>通知提醒频次</label>
                    <span style="margin-right: 20px;">
                        <input
                            type="radio"
                            :value="0"
                            v-model="submitParams.disposable"
                        />
                        <span style="margin: 0 10px;">单次</span>
                    </span>
                    <span>
                        <input
                            type="radio"
                            :value="1"
                            v-model="submitParams.disposable"
                        />
                        <span style="margin: 0 10px;">循环</span>
                    </span>
                </div>
                <div>
                    <label>通知提醒周期</label>
                    <input
                        type="text"
                        maxlength="3"
                        style="width: 400px; height: 40px; border-radius: 4px; border: 1px solid #979797; padding-left: 10px; margin-right: 10px;"
                        v-model="submitParams.scheduleDays"
                        placeholder="请输入"
                    />
                    <span>天</span>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import {
    queryDepartmentUrl,
    addGeneralFormUrl,
    editGeneralFormUrl,
} from "@/requestUrl";
import { ORG_LEVEL } from "@/constant";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";

export default {
    props: {
        name: String,
        orgList: Array,
        componentName: String,
        department: [String, Number],
    },
    components: {
        CSDialog,
        CSSelect,
    },
    data() {
        return {
            dialogTitle:"添加考核表",
            checkedOrg: {},
            isSubmitting: false,
            submitParams: {
                name: "",
                regionId: this.$vc.getCurrentRegion().communityId,
                orgId: "",
                dept: "",
                target: "",
                disposable: "",
                scheduleDays: "",
            },
            staffInfo: this.$vc.getCurrentStaffInfo(),
            addAssessmentVisivle: false,
        };
    },

    mounted() {
        this.$vc.on(this.$route.path, "addCommonTable", "show", (params) => {
            this.submitParams.target = params.target;
            this.orgList.forEach((val) => {
                if (
                    val.code.toString() ===
                    this.$vc.getCurrentStaffInfo().departmentCode.toString()
                ) {
                    this.checkedOrg = val;
                }
            });
            if (params.tableInfo) {
                const { tableInfo } = params;
                this.$vc.copyObject(tableInfo, this.submitParams);
                this.submitParams.formId = tableInfo.id;
                this.submitParams.regionId = this.$vc.getCurrentRegion().communityId;
                this.orgList.forEach((val) => {
                    if (val.name === tableInfo.orgName) {
                        this.checkedOrg = val;
                        this.submitParams.orgId = val.code;
                        this.submitParams.dept = val.dutyType;
                    }
                });
            } else {
                this.submitParams = {
                    name: "",
                    regionId: this.$vc.getCurrentRegion().communityId,
                    orgId: "",
                    dept: "",
                    target: params.target,
                    disposable: "",
                    scheduleDays: "",
                };
            }
            this.addAssessmentVisivle = true;
            console.log(params.tableInfo);
        });
    },
    methods: {
        submitCommonAssessment() {
            let params = {};
            for (let key in this.submitParams) {
                params[key] = this.submitParams[key];
            }
            params["dept"] = this.checkedOrg.dutyType;
            params["orgId"] = this.checkedOrg.code;
            params.operatorId = this.$vc.getCurrentStaffInfo().id;
            const isEditRequest = params.formId && params.formId !== "";
            this.isSubmitting = true;
            this.$fly
                .post(
                    isEditRequest ? editGeneralFormUrl : addGeneralFormUrl,
                    params
                )
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("添加成功");
                    this.addAssessmentVisivle = false;
                    this.$vc.emit(this.$route.path, "assessmentManage", "list", null);
                })
                .finally(() => this.isSubmitting = false);
        },
    },
};
</script>
<style lang="stylus" scoped>
.dialog-content
    & > div > label
      width 146px
      text-align right
      margin-right 20px
.modal
    &-content
        width 640px
    &-body
        font-size 24px
        color #444
        .col-form-label
            margin-right 40px
            width 145px
            text-align right
        .cs-input
            width 400px
            height 40px
            max-height 200px
            outline none
            vertical-align middle
            border 1px solid #979797
            border-radius 4px
        input[type='radio'], span
            vertical-align middle
            margin-right 5px
    &-footer
        text-align center
        button
            font-size 24px
            &:not(:last-of-type)
                margin-right 30px
</style>
